import { Directive, HostBinding } from '@angular/core';
import { ThemeSettingsService, DashboardTheme } from './services/theme-settings/theme-settings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[appThemeControl]'
})
export class ThemeControlDirective {

  dashboardTheme: DashboardTheme;

  constructor(private service: ThemeSettingsService, private sanitizer: DomSanitizer) { 

    service.theme$.subscribe((theme) => {
      this.dashboardTheme = theme;
    });
  }

  @HostBinding('attr.style')
  get style() {

    if (this.dashboardTheme) {
      return this.sanitizer.bypassSecurityTrustStyle(`--primary-color: ${this.dashboardTheme.colors.primary};
      --fonr-color: ${this.dashboardTheme.colors.font};`);
    }
  }

}
