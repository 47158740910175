import { Injectable } from '@angular/core';
import { AuthenticationService, AuthUser } from '../authentication/authentication-service';
import { BehaviorSubject } from 'rxjs';
import { LocationService } from '../location-on-fire/location.service';

export interface ColorOptions {
  primary: string;
  font: string;
}

export interface DashboardTheme {
  logo: string;
  bkgImage: string;
  colors: ColorOptions;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeSettingsService {

  theme$ = new BehaviorSubject<DashboardTheme>(null);

  constructor(private authService: AuthenticationService, locationAfs: LocationService) {

    authService.currentUser.subscribe((user: AuthUser) => {
      if (user && user.role === 'MODERATOR') {

        locationAfs.getList(1).then((list) => {
          const location = list[0];
          const theme = {colors: location.color, logo: location.logo, bkgImage: location.bkgImage } as DashboardTheme;
          this.theme$.next(theme);
        }).catch((err) => console.error(err));
      }
    });
  }

  reset() {
    const theme = {
      logo: '',
      bkgImage: '',
      colors: {
        primary: '#fff',
        font: '#000',
      }
    };
    this.theme$.next(theme);
  }

  update(theme: DashboardTheme) {
    const user = this.authService.currentUser.getValue();

    if (user && user.role === 'MODERATOR') {

      this.theme$.next(theme);
    }
  }
}
