import { Component, OnInit } from '@angular/core';
import { ThemeSettingsService, DashboardTheme } from 'src/app/services/theme-settings/theme-settings.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-theme-background',
  templateUrl: './theme-background.component.html',
  styleUrls: ['./theme-background.component.scss']
})
export class ThemeBackgroundComponent implements OnInit {

  dashboardTheme: BehaviorSubject<DashboardTheme>;

  constructor(themeServise: ThemeSettingsService) {

    this.dashboardTheme = themeServise.theme$;
  }

  ngOnInit() {
  }

}
