import { NgModule, APP_INITIALIZER } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

import { SignInComponent } from './sign-in/sign-in.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { AppComponent } from './app.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo, isNotAnonymous } from '@angular/fire/auth-guard';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MccColorPickerModule } from 'material-community-components';



import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { UsersComponent } from './pages/users/users.component';
import { LocationItemComponent } from './pages/location-item/location-item.component';
import { LocationNewComponent } from './pages/location-new/location-new.component';
import { VisitsComponent } from './pages/visits/visits.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CompanyItemComponent } from './pages/company-item/company-item.component';
import { CompanyNewComponent } from './pages/company-new/company-new.component';
import { FilesOnFireUploaderComponent } from './components/files-on-fire-uploader/files-on-fire-uploader.component';
import { FilesDropZoneDirective } from './components/files-on-fire-uploader/files-drop-zone.directive';
import { FileSizePipe } from './components/files-on-fire-uploader/file-size.pipe';
import { CheckPasswordDialogComponent } from './components/check-password-dialog/check-password-dialog.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { UserCreateComponent } from './pages/user-create/user-create.component';
import { CompanyFormComponent } from './components/company-form/company-form.component';
import { ThemeControlDirective } from './theme-control.directive';
import { ThemeBackgroundComponent } from './components/theme-background/theme-background.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['/']);

const maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

const routes: Routes = [
  {
    path: '', component: HomeComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      { path: '', redirectTo: 'locations', pathMatch: 'full' },
      { path: 'users', component: UsersComponent },
      { path: 'users/add', component: UserCreateComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'companies', component: CompaniesComponent },
      { path: 'companies/add', component: CompanyNewComponent },
      { path: 'companies/:id', component: CompanyItemComponent },
      { path: 'locations', component: LocationsComponent },
      { path: 'locations/add', component: LocationNewComponent },
      { path: 'locations/:id', component: LocationItemComponent },
      { path: 'locations/:id/visits', component: VisitsComponent }
    ]
  },
  {
    path: 'login', component: SignInComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'forgot-password', component: ForgotPasswordFormComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'mail_v9n', component: EmailVerificationComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome }
  }
];

@NgModule({
  declarations: [
    SignInComponent,
    EmailVerificationComponent,
    ForgotPasswordFormComponent,
    LoginFormComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ProfileComponent,
    CompaniesComponent,
    CompanyItemComponent,
    CompanyNewComponent,
    CompanyFormComponent,
    LocationsComponent,
    LocationItemComponent,
    LocationNewComponent,
    VisitsComponent,
    UsersComponent,
    UserCreateComponent,
    FilesOnFireUploaderComponent,
    FilesDropZoneDirective,
    FileSizePipe,
    ConfirmationDialogComponent,
    CheckPasswordDialogComponent,
    ThemeControlDirective
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    CheckPasswordDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatPasswordStrengthModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    RouterModule.forRoot(routes),
    NgxMaskModule.forRoot(maskOptions),
    MaterialModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgxUiLoaderModule,
    MccColorPickerModule
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppRoutingModule { }
