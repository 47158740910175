import { Component, OnInit } from '@angular/core';
import { CompanyOnFireService, Company } from 'src/app/services/company-on-fire/company-on-fire.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { QueryDocumentSnapshot, DocumentReference } from '@angular/fire/firestore';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { AngularFireStorage } from '@angular/fire/storage';
import { LocationService } from 'src/app/services/location-on-fire/location.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  companies: Company[];
  locationDoc: DocumentReference;

  search: string;
  pageSize = 10;
  offset = 0;

  lastItem: QueryDocumentSnapshot<Company> = null;
  firstItem: QueryDocumentSnapshot<Company> = null;
  prevFistItem: QueryDocumentSnapshot<Company>[] = [];

  private _getCollection(locationRef: DocumentReference,
                         startAfterItem: QueryDocumentSnapshot<Company> = null,
                         startAtItem: QueryDocumentSnapshot<Company> = null,
                         endBeforeItem: QueryDocumentSnapshot<Company> = null) {

    this.afs.getList(locationRef, this.pageSize, startAfterItem, startAtItem, endBeforeItem).then((list: Company[]) => {

      this.companies = [...list];

      if (list.length < 1) {

        this.lastItem = null;
      } else {
        this.firstItem = list.length > 0 ? (list.shift()).snapshot : null;
        this.lastItem = list.length > 0 ? (list.pop()).snapshot : null;
      }
    });
  }

  constructor(locationsAfs: LocationService,
              route: ActivatedRoute, private fireStore: AngularFireStorage,
              private afs: CompanyOnFireService, private dialog: MatDialog) {

    const locationId = route.snapshot.queryParamMap.get('locationId');

    locationsAfs.getItem(locationId).then((location) => {

      this.locationDoc = location.snapshot;
      this._getCollection(this.locationDoc);

    }).catch(err => {});
  }

  ngOnInit() {

  }

  public pageChanged(direction: string): void {

    if (direction === 'forward') {
      this.offset += this.pageSize;

      this.prevFistItem.push(this.firstItem);
      this._getCollection(this.locationDoc, this.lastItem);
    } else if (direction === 'backward') {
      this.offset -= this.pageSize;

      this._getCollection(this.locationDoc, null, this.prevFistItem.pop(), this.lastItem);
    }
  }

  delete(company: Company) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe(async status => {

      if (status === 'confirmed') {

        try {
          await this.afs.deleteItem(company.id);

          const startAfter = this.prevFistItem.length > 0 ? this.prevFistItem[this.prevFistItem.length - 1] : null;
          this._getCollection(this.locationDoc, startAfter);
        } catch (err) {
          console.error(err);
        }
      }
    });
  }

  get pageNumber() {
    return this.offset / this.pageSize + 1;
  }

  get displayColumns() {
    return ['logo', 'name', 'actions'];
  }
}
