import { Component, OnInit } from '@angular/core';
import { LocationService, Location } from 'src/app/services/location-on-fire/location.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { DocumentSnapshot } from '@angular/fire/firestore';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  locations: Location[];

  search: string;
  pageSize = 10;
  offset = 0;

  lastItem: DocumentSnapshot<Location> = null;
  firstItem: DocumentSnapshot<Location> = null;
  prevFistItem: DocumentSnapshot<Location>[] = [];

  constructor(private service: LocationService, private dialog: MatDialog) {
  }

  ngOnInit() {

    // this.ngxLoader.start();

    setTimeout(() => {
      this._getCollection();
      // this.ngxLoader.stop();
    }, 500);
  }

  private _getCollection(startAfterItem: DocumentSnapshot<Location> = null,
                         startAtItem: DocumentSnapshot<Location> = null,
                         endBeforeItem: DocumentSnapshot<Location> = null) {
    this.service.getList(this.pageSize, startAfterItem, startAtItem, endBeforeItem).then((list: Location[]) => {

      this.locations = [...list];

      if (list.length < 1) {
        this.lastItem = null;
      } else {
        this.firstItem = list.length > 0 ? (list.shift()).snapshot : null;
        this.lastItem = list.length > 0 ? (list.pop()).snapshot : null;
      }
    });
  }

  public pageChanged(direction: string): void {

    if (direction === 'forward') {
      this.offset += this.pageSize;

      this.prevFistItem.push(this.firstItem);
      this._getCollection(this.lastItem);
    } else if (direction === 'backward') {
      this.offset -= this.pageSize;
      this._getCollection(null, this.prevFistItem.pop(), this.lastItem);
    }
  }

  delete(id: string) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe(async status => {

      if (status === 'confirmed') {
        await this.service.deleteItem(id);

        const startAfter = this.prevFistItem.length > 0 ? this.prevFistItem[this.prevFistItem.length - 1] : null;
        this._getCollection(startAfter);
      }
    });
  }

  get pageNumber() {
    return this.offset / this.pageSize + 1;
  }

  get displayColumns() {
    return ['name', 'oHours', 'actions'];
  }
}
