import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UsersOnFireService } from 'src/app/services/users-on-fire/users-on-fire.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  userInviteForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.email, Validators.required]),
    role: new FormControl('', [Validators.required]),
    _server: new FormControl(''),
  });

  constructor(private authService: AuthenticationService,
              private usersAfs: UsersOnFireService,
              private router: Router,
              private ngxLoader: NgxUiLoaderService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  invite() {

    if (this.userInviteForm.valid) {

      this.ngxLoader.start();

      const email = this.userInviteForm.get('email').value;
      const role  = this.userInviteForm.get('role').value;
      const password = Math.random().toString(36).slice(-8);

      this.authService.createUserAccount(email, password)
      .then(async (user) => {

        try {
          await this.usersAfs.updateItem({email, role}, user.uid);
        } catch (err) {
          this.userInviteForm.controls._server.setErrors({data: err});
          return this.ngxLoader.stop();
        }

        try {
          await this.authService.sendSignInLink(email);
          this.ngxLoader.stop();
          this.router.navigate(['/users']);
        } catch (err) {
          this.userInviteForm.controls._server.setErrors({data: err});
          return this.ngxLoader.stop();
        }
      })
      .catch((err) => {
        this.ngxLoader.stop();
        this.userInviteForm.controls._server.setErrors({data: err});
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.userInviteForm.controls; }

}
