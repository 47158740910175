import { Component, OnInit, ViewChild } from '@angular/core';
import { Location, OHour, LocationService } from 'src/app/services/location-on-fire/location.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { FilesOnFireUploaderComponent } from 'src/app/components/files-on-fire-uploader/files-on-fire-uploader.component';

@Component({
  selector: 'app-location-new',
  templateUrl: './location-new.component.html',
  styleUrls: ['./location-new.component.scss']
})
export class LocationNewComponent implements OnInit {

  location: Location;
  @ViewChild('logoUploader', { static: false }) logoUploader: FilesOnFireUploaderComponent;
  @ViewChild('bkgImgUploader', { static: false }) bkgImgUploader: FilesOnFireUploaderComponent;

  constructor(private router: Router,
              private readonly afs: LocationService,
              private ngxLoader: NgxUiLoaderService,
              private authService: AuthenticationService) { }

  ngOnInit() {
    this.location = this.afs.getNewItem();

    const user = this.authService.getUserData();

    if (user.role === 'RESELLER') {
      this.location.reseller = user.ref;
    }
  }

  get uploadeDirName() {
    return 'locations/' + this.location.id;
  }

  async addNewLocation() {

    const payload = { ...this.location };

    this.ngxLoader.start();

    try {
      const location = await this.afs.addItem(payload);
      this.location.id = location.id;
    } catch (e) {
      console.error(e);
      return true;
    }

    const logoImgSnapshot = await this.logoUploader.startUpload(this.uploadeDirName);
    if (logoImgSnapshot != null) {
      this.location.logo = payload.logo = await logoImgSnapshot.getDownloadURL().toPromise();
    }

    const bkgImgSnapshot = await this.bkgImgUploader.startUpload(this.uploadeDirName);
    if (bkgImgSnapshot != null) {
      this.location.bkgImage = payload.bkgImage = await bkgImgSnapshot.getDownloadURL().toPromise();
    }

    try {
      await this.afs.updateItem(payload, this.location.id);
    } catch (err) {
      console.error(err);
    }

    this.authService.sendSignInLink(this.location.email).then(() => {

      this.ngxLoader.stop();
      this.router.navigate(['/locations']);
    });
  }

  changeColors(color, name) {
    this.location.color[name] = color;
  }
}
