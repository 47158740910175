import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from '../_helpers';
import { AuthenticationService } from '../services/authentication/authentication-service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {

  passwordResetForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.email, Validators.required])
  });

  public isResetPasswordSent = false;

  constructor(private authService: AuthenticationService, private formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  verify() {

    if (this.passwordResetForm.valid) {

      this.authService.resetPassword(this.passwordResetForm.get('email').value)
        .then((res) => this.isResetPasswordSent = true)
        .catch((err) => {

          this.passwordResetForm.controls.email.setErrors({server: err.message});
        });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.passwordResetForm.controls; }

}
