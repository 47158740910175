import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Output() submitEM = new EventEmitter();

  signInForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  constructor() { }

  ngOnInit() {
  }

  submit() {
    if (this.signInForm.valid) {
      this.submitEM.emit(this.signInForm.value);
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.signInForm.controls; }
}
