import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../services/authentication/authentication-service';
import { MustMatch } from '../_helpers';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  generalServerError: '';

  public mode: string;

  emailVerificationForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [Validators.required]),
    password_repeat: new FormControl('', [Validators.required]),
    _server: new FormControl(''),
  }, {
    validators: MustMatch('password', 'password_repeat')
  });

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private ngxLoader: NgxUiLoaderService,
              private authService: AuthenticationService, private formBuilder: FormBuilder) {

    /*const continueUrl = decodeURI(this.activatedRoute.snapshot.queryParamMap.get('continueUrl'));

    const docLocation = document.location;

    if (continueUrl != (docLocation.origin + docLocation.pathname)) {
      docLocation.href = continueUrl + docLocation.search;
    }*/
  }

  ngOnInit() {
    this.mode = this.activatedRoute.snapshot.queryParamMap.get('mode');
  }

  async verify() {

    if (this.emailVerificationForm.valid) {
      const oobCode = this.activatedRoute.snapshot.queryParamMap.get('oobCode');

      this.ngxLoader.start();

      if (this.mode === 'resetPassword') {

        try {
          const emailAddress = await this.authService.getAuth().verifyPasswordResetCode(oobCode);
        } catch (err) {
          this.emailVerificationForm.controls._server.setErrors({ data: err });
          return this.ngxLoader.stop();
        }

        try {
          await this.authService.getAuth().confirmPasswordReset(oobCode, this.emailVerificationForm.get('password').value);
        } catch (err) {
          this.emailVerificationForm.controls._server.setErrors({ data: err });
          return this.ngxLoader.stop();
        }

      } else if (this.mode === 'signIn') {

        try {
          await this.authService.signInWithEmail(this.emailVerificationForm.get('email').value, window.location.href);
        } catch (err) {
          console.log(err);
          this.emailVerificationForm.controls._server.setErrors({ data: err });
          return this.ngxLoader.stop();
        }

        try {
          await this.authService.updatePassword(this.emailVerificationForm.get('password').value);
        } catch (err) {
          console.log(err);
          this.emailVerificationForm.controls._server.setErrors({ data: err });
          return this.ngxLoader.stop();
        }
      }
      this.ngxLoader.stop();
      this.router.navigate(['/']);
    }
  }

  get formTitle() {
    if (this.mode === 'signIn') {
      return 'Welcome!';
    } else if (this.mode === 'resetPassword') {
      return 'Reset your password';
    }
    return '';
  }

  // convenience getter for easy access to form fields
  get f() { return this.emailVerificationForm.controls; }

}
