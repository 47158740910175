// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBLg4oq1m5U3ZpvBCoGIlSnpIplUcJuKd8',
    authDomain: 'company-locator-backend.firebaseapp.com',
    databaseURL: 'https://company-locator-backend.firebaseio.com',
    projectId: 'company-locator-backend',
    storageBucket: 'company-locator-backend.appspot.com',
    messagingSenderId: '568927242910',
    appId: '1:568927242910:web:70f3518927081d7329c98f',
    measurementId: 'G-PRSWFHN26B'
  }
};

export const actionCodeSettings = {
  url: 'https://localhost:4200/signInWithEmail',
  handleCodeInApp: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
