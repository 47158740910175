import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryDocumentSnapshot, DocumentReference } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

export interface Company {
  id?: string;
  name: string;
  logo: string;
  path: string;
  path_showtime: number;
  floor_plan: string;
  floor_plan_showtime: number;
  room?: string;
  location: DocumentReference | null;
  oHours: OHour[];
  snapshot?: QueryDocumentSnapshot<Company> | null;
}

export interface OHour {
  day: number | Array<number>;
  open: string;
  close: string;
}

export const CollectionName = 'Companies';

@Injectable({
  providedIn: 'root'
})
export class CompanyOnFireService {

  private collection: AngularFirestoreCollection<Company>;

  constructor(private readonly afs: AngularFirestore) {

    this.collection = this.afs.collection<Company>(CollectionName);
  }

  getList(locationRef: DocumentReference, pageSize: number, startAfterItem: QueryDocumentSnapshot<Company> = null,
    startAtItem: QueryDocumentSnapshot<Company> = null, endBeforeItem: QueryDocumentSnapshot<Company> = null) {

    function prepStructure(doc) {
      return { id: doc.id, snapshot: doc, ...doc.data() } as Company;
    }

    let queryRef: firebase.firestore.Query = this.collection.ref.orderBy('name').limit(pageSize);

    if (startAfterItem !== null) {
      queryRef = queryRef.startAfter(startAfterItem);
    } else if (endBeforeItem !== null) {
      queryRef = queryRef.startAt(startAtItem)
        .endBefore(endBeforeItem);
    } else if (startAtItem !== null) {
      queryRef = queryRef.startAt(startAfterItem);
    }

    queryRef = queryRef.where('location', '==', locationRef);

    return new Promise<Company[]>(async (success, error) => {

      try {
        const qSnapshot = await this.afs.collection<Company>(CollectionName, ref => queryRef).get().toPromise();
        success(qSnapshot.docs.map(prepStructure));
      } catch (err) {
        error(err);
      }
    });
  }

  getItem(id) {
    return new Promise<any>((resolve, reject) => {
      this.afs.doc(CollectionName + '/' + id).snapshotChanges()
        .subscribe(company => {
          const data = { id: company.payload.id, ...company.payload.data() } as Company;
          resolve(data);
        });
    });
  }

  getNewItem() {
    return {
      logo: '',
      floor_plan: null,
      floor_plan_showtime: 0,
      path: null,
      path_showtime: 0,
      name: '',
      oHours: [],
      room: '',
      location: null
    } as Company;
  }

  addItem(payload: Company) {

    delete payload.id;
    delete payload.snapshot;

    return this.collection.add(payload);
  }

  updateItem(payload: Company, id: string) {

    delete payload.id;
    delete payload.snapshot;

    return this.collection.doc(id).set(payload, { merge: true });
  }

  deleteItem(id: string) {
    return this.collection.doc(id).delete();
  }
}
