import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication-service';
import { LoginFormComponent } from '../components/login-form/login-form.component';
import { Router } from '@angular/router';

interface UserCredentials {
  username: string;
  password: string;
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  entryComponents: [LoginFormComponent]
})
export class SignInComponent implements OnInit {

  public serverError: any;

  constructor(public router: Router, public authenticationService: AuthenticationService) {
  }

  ngOnInit() {
  }

  onSubmit(cred: UserCredentials)
  {
    this.authenticationService.SignIn(cred.username, cred.password)
    .then(async (res) => {
      this.router.navigate(['/']);
    }).catch((error) => {

      this.serverError = error.message;
    });
  }

}
