import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentSnapshot, DocumentReference } from '@angular/fire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { Company } from '../company-on-fire/company-on-fire.service';
import { AuthenticationService, AuthUser } from '../authentication/authentication-service';
import { of, Subject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';


export interface Location {
  id?: string;
  name: string;
  logo: string;
  bkgImage: string;
  color: ColorOptions;
  email: string;
  oHours?: OHour[];
  moderator?: DocumentReference;
  reseller?: DocumentReference;
  snapshot?: any;
}

export interface OHour {
  day: number;
  open: string;
  close: string;
}

export interface ColorOptions {
  primary: string;
  font: string;
}

export const CollectionName = 'Locations';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private collection: AngularFirestoreCollection<Location>;

  constructor(private afs: AngularFirestore, private auth: AuthenticationService) {
    this.collection = this.afs.collection<Location>(CollectionName);
  }

  getList(pageSize: number, startAfterItem: DocumentSnapshot<Location> = null,
          startAtItem: DocumentSnapshot<Location> = null,
          endBeforeItem: DocumentSnapshot<Location> = null) {

    function prepStructure(doc) {
      return  { id: doc.id, snapshot: doc, ...doc.data() } as Location;
    }
    let queryRef: firebase.firestore.Query = this.collection.ref.orderBy('name').limit(pageSize);

    if (startAfterItem !== null) {
      queryRef = queryRef.startAfter(startAfterItem);
    } else if (endBeforeItem !== null) {
      queryRef = queryRef.startAt(startAtItem)
        .endBefore(endBeforeItem);

    } else if (startAtItem !== null) {
      queryRef = queryRef.startAt(startAfterItem);
    }

    const user = this.auth.getUserData();

    if (user) {
      if (user.role === 'MODERATOR') {
        queryRef = queryRef.where('moderator', '==', user.ref);
      } else if (user.role === 'RESELLER') {
        queryRef = queryRef.where('reseller', '==', user.ref);
      }
    }

    return new Promise<Location[]>(async (success, error) => {

      try {
        const qSnapshot = await this.afs.collection<Location>(CollectionName, ref => queryRef).get().toPromise();
        success(qSnapshot.docs.map(prepStructure));
      } catch (err) {
        error(err);
      }
    });
  }

  getNewItem() {
    const location = {name: '', email: '', oHours: []} as Location;
    location.oHours.push({ day: 0, open: '00:00', close: '00:00' } as OHour);
    location.color = {primary: '', font: ''};
    return location;
  }

  getItem(id) {
    return this.afs.doc(CollectionName + '/' + id).get().pipe(map(res => {

      console.log(res);
      return { id: res.id, snapshot: res.ref, ...res.data() } as Location;
    })).toPromise();
  }

  addItem(payload: Location) {

    delete payload.id;
    delete payload.snapshot;

    return this.collection.add(payload);
  }

  updateItem(payload: Location, id: string) {

    delete payload.id;
    delete payload.snapshot;

    return this.collection.doc(id).set(payload);
  }

  deleteItem(id: string) {
    return this.collection.doc(id).delete();
  }
}
