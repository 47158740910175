import { Component, OnInit, ViewChild } from '@angular/core';
import { Company, OHour, CompanyOnFireService } from 'src/app/services/company-on-fire/company-on-fire.service';
import { shared } from 'src/app/shared/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { FilesOnFireUploaderComponent } from 'src/app/components/files-on-fire-uploader/files-on-fire-uploader.component';
import { LocationService } from 'src/app/services/location-on-fire/location.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-company-new',
  templateUrl: './company-new.component.html',
  styleUrls: ['./company-new.component.scss']
})
export class CompanyNewComponent implements OnInit {

  company: Company;
  @ViewChild('logoUploader', { static: false }) logoUploader: FilesOnFireUploaderComponent;
  @ViewChild('pathImgUploader', { static: false }) pathImgUploader: FilesOnFireUploaderComponent;
  @ViewChild('floorPlanImgUploader', { static: false }) floorPlanImgUploader: FilesOnFireUploaderComponent;

  constructor(private readonly route: ActivatedRoute,
              private router: Router,
              private ngxLoader: NgxUiLoaderService,
              private afs: CompanyOnFireService,
              private locationsAfs: LocationService) {

    this.company = afs.getNewItem();
  }

  get isCompanyDefined() {
    return typeof this.company !== 'undefined';
  }

  get uploadeDirName() {
    return 'companies/' + this.company.id;
  }

  ngOnInit() {

    const locationId = this.route.snapshot.queryParamMap.get('locationId');

    this.locationsAfs.getItem(locationId).then((location) => {
      this.company.location = location.snapshot;
    });
  }

  async addNewCompany(payload: Company) {

    this.ngxLoader.start();

    try {
      const companyDocument = await this.afs.addItem(payload);
      this.company.id = companyDocument.id;
    } catch (err) {
      console.error(err);
      return;
    }

    const logoImgSnapshot = await this.logoUploader.startUpload(this.uploadeDirName);
    if (logoImgSnapshot != null) {
      this.company.logo = await logoImgSnapshot.getDownloadURL().toPromise();
    }

    const pathImgSnapshot = await this.pathImgUploader.startUpload(this.uploadeDirName);
    if (pathImgSnapshot != null) {
      this.company.path = await pathImgSnapshot.getDownloadURL().toPromise();
      this.company.path_showtime = this.company.path_showtime === 0 ? 30 : this.company.path_showtime;
    }

    if (!this.company.path) {
      this.company.path_showtime = 0;
    }

    const floorPlanImgSnapshot = await this.floorPlanImgUploader.startUpload(this.uploadeDirName);
    if (floorPlanImgSnapshot != null) {
      this.company.floor_plan = await floorPlanImgSnapshot.getDownloadURL().toPromise();
      this.company.floor_plan_showtime = this.company.floor_plan_showtime === 0 ? 30 : this.company.floor_plan_showtime;
    }

    if (!this.company.floor_plan) {
      this.company.floor_plan_showtime = 0;
    }

    payload = {...this.company, ...payload};

    try {
      await this.afs.updateItem(payload, this.company.id);

      this.router.navigate(['/companies'], { queryParams: { locationId: this.route.snapshot.queryParamMap.get('locationId') } });
      this.ngxLoader.stop();
    } catch (err) {
      console.error(err);
    }
  }
}
