import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { actionCodeSettings} from '../../../environments/environment';
import { of, Subject, BehaviorSubject } from 'rxjs';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from '../../../environments/environment';
import { initializeApp, User as FireUser } from 'firebase/app';

export interface AuthUser {
  id: string;
  name: string;
  email: string;
  role: string;
  ref: DocumentReference;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  currentUser: BehaviorSubject<AuthUser> | null =  new BehaviorSubject<AuthUser>(null);

  constructor(private angularFireAuth: AngularFireAuth, private afs: AngularFirestore, private ngxPermissions: NgxPermissionsService) {

    this.angularFireAuth.authState.pipe(switchMap(user => {

      if (user) {
        return this.afs.doc('Users/' + user.uid).snapshotChanges();
      } else {
        return of(null);
      }
    })).subscribe((res) => {

      if (res != null) {
        const payload = {id: res.payload.id, ref: res.payload.ref, ...res.payload.data()} as AuthUser;
        this.currentUser.next(payload);
      } else {
        this.currentUser.next(null);
      }
    });
  }

   /* Sign in */
   SignIn(email: string, password: string) {

    return this.getAuth().signInWithEmailAndPassword(email, password);
  }

  /* Sign out */
  SignOut() {
    this.getAuth().signOut();
  }

  sendSignInLink(email) {
    return this.getAuth().sendSignInLinkToEmail(email, actionCodeSettings);
  }

  signInWithEmail(email, link) {
    return this.getAuth().signInWithEmailLink(email, link);
  }

  resetPassword(email) {
    return this.getAuth().sendPasswordResetEmail(email);
  }

  updateEmail(email) {

    return this.getAuth().currentUser.updateEmail(email);
  }

  updatePassword(password) {

    return this.getAuth().currentUser.updatePassword(password);
  }

  getUserData() {
    return this.currentUser.getValue();
  }

  createUserAccount(email, password) {

    const tempApp = initializeApp(environment.firebaseConfig, 'temp');
    return new Promise<FireUser>((success, reject) => {

      tempApp.auth().createUserWithEmailAndPassword(email, password).then((res) => {
        tempApp.auth().signOut();
        success(res.user);
      }).catch(err => reject(err));
    });
  }

  getAuth() {
    return this.angularFireAuth.auth;
  }
}
