import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication-service';

export interface User {
  email: string;
  role: string;
  snapshot?: DocumentSnapshot<User> | null;
}

const CollectionName = 'Users';

@Injectable({
  providedIn: 'root'
})
export class UsersOnFireService {

  private collection: AngularFirestoreCollection<User>;

  constructor(private readonly afs: AngularFirestore, private auth: AuthenticationService) {

    this.collection = this.afs.collection<User>(CollectionName);
  }

  getList(pageSize: number,
          startAfterItem: DocumentSnapshot<User> = null,
          startAtItem: DocumentSnapshot<User> = null,
          endBeforeItem: DocumentSnapshot<User> = null) {

    function prepStructure(doc) {
      return  { id: doc.id, snapshot: doc, ...doc.data() } as User;
    }

    let queryRef: firebase.firestore.Query = this.collection.ref.orderBy('role').limit(pageSize);

    if (startAfterItem !== null) {
      queryRef = queryRef.startAfter(startAfterItem);
    } else if (endBeforeItem !== null) {
      queryRef = queryRef.startAt(startAtItem)
      .endBefore(endBeforeItem);
    } else if (startAtItem !== null) {
      queryRef = queryRef.startAt(startAtItem);
    }

    const user = this.auth.getUserData();

    if (user) {
      if (user.role === 'RESELLER') {
        queryRef = queryRef.where('reseller', '==', user.ref);
      }
    }

    return new Promise<User[]>(async (success, error) => {

      try {
        const qSnapshot = await this.afs.collection<User>(CollectionName, ref => queryRef).get().toPromise();
        success(qSnapshot.docs.map(prepStructure));
      } catch (err) {
        error(err);
      }
    });
  }

  getItem(id) {
    return new Promise<any>((resolve, reject) => {
      this.afs.doc(CollectionName + '/' + id).snapshotChanges()
        .subscribe(company => {
          const data = { id: company.payload.id, ...company.payload.data() } as User;
          resolve(data);
        });
    });
  }

  updateItem(payload: User, id: string) {

    return this.collection.doc(id).set(payload);
  }

  deleteItem(id: string) {
    return this.collection.doc(id).delete();
  }
}
