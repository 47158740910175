import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule} from '@angular/fire/auth-guard';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { AuthenticationService } from './services/authentication/authentication-service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ThemeBackgroundComponent } from './components/theme-background/theme-background.component';

@NgModule({
  declarations: [
    AppComponent,
    ThemeBackgroundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    MatPasswordStrengthModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthenticationService, ngxPermissions: NgxPermissionsService) => {
        return () => new Promise((resolve, reject) => {

          authService.currentUser.subscribe((user) => {

            ngxPermissions.addPermission('ANONYMOUS', () => user == null);
            ngxPermissions.addPermission('MODERATOR', () => user.role === 'MODERATOR');
            ngxPermissions.addPermission('RESELLER', () => user.role === 'RESELLER');
            ngxPermissions.addPermission('ADMIN', () => user.role === 'ADMIN');

            resolve();
          });
        });
      },
      deps: [AuthenticationService, NgxPermissionsService],
      multi: true
    }
  ],
})
export class AppModule { }
