import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location, LocationService, ColorOptions } from 'src/app/services/location-on-fire/location.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FilesOnFireUploaderComponent } from 'src/app/components/files-on-fire-uploader/files-on-fire-uploader.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { DashboardTheme, ThemeSettingsService } from 'src/app/services/theme-settings/theme-settings.service';

@Component({
  selector: 'app-location-item',
  templateUrl: './location-item.component.html',
  styleUrls: ['./location-item.component.scss']
})
export class LocationItemComponent implements OnInit {

  location: Location;

  @ViewChild('logoUploader', { static: false }) logoUploader: FilesOnFireUploaderComponent;
  @ViewChild('bkgImgUploader', { static: false }) bkgImgUploader: FilesOnFireUploaderComponent;

  constructor(private readonly route: ActivatedRoute,
              private ngxLoader: NgxUiLoaderService,
              private service: LocationService,
              private themeServise: ThemeSettingsService,
              private fireStore: AngularFireStorage) { }

  ngOnInit() {
    this.service.getItem(this.route.snapshot.paramMap.get('id')).then(location => {

      if (!location.hasOwnProperty('color')) {
        location.color = {primary: '', font: ''} as ColorOptions;
      }
      this.location = location;
    });
  }

  get uploadeDirName() {
    return 'locations/' + this.location.id;
  }

  changeColors(color, name) {
    this.location.color[name] = color;
  }

  async update() {

    this.ngxLoader.start();

    const logoImgSnapshot = await this.logoUploader.startUpload(this.uploadeDirName);
    if (logoImgSnapshot != null) {

      if (this.location.logo) {
        await this.fireStore.storage.refFromURL(this.location.logo).delete();
      }
      this.location.logo = await logoImgSnapshot.getDownloadURL().toPromise();
    }

    const bkgImgSnapshot = await this.bkgImgUploader.startUpload(this.uploadeDirName);
    if (bkgImgSnapshot != null) {

      if (this.location.bkgImage) {
        await this.fireStore.storage.refFromURL(this.location.bkgImage).delete();
      }
      this.location.bkgImage = await bkgImgSnapshot.getDownloadURL().toPromise();
    }

    const payload = { ...this.location };

    this.service.updateItem(payload, this.location.id).then(() => {

      const theme = {
        logo: this.location.logo,
        bkgImage: this.location.bkgImage,
        colors: { ...this.location.color }
      } as DashboardTheme;

      this.themeServise.update(theme);

      this.ngxLoader.stop();
    });
  }
}
