import { Injectable } from '@angular/core';
import { DocumentReference, AngularFirestore, AngularFirestoreCollection, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { resolve } from 'path';

export interface Visit {
  // company: DocumentReference;
  // location: DocumentReference;
  companyName: string;
  snapshot?: QueryDocumentSnapshot<Visit>;
  registered: Date;
}

export const CollectionName = 'Visits';

@Injectable({
  providedIn: 'root'
})
export class VisitsOnFireService {

  private collection: AngularFirestoreCollection<Visit>;

  constructor(private afs: AngularFirestore) {
    this.collection = this.afs.collection<Visit>(CollectionName);
  }

  getList(locationRef: DocumentReference, pageSize: number, startAfterItem: QueryDocumentSnapshot<Visit> = null,
          startAtItem: QueryDocumentSnapshot<Visit> = null, endBeforeItem: QueryDocumentSnapshot<Visit> = null) {

    function prepStructure(doc) {
      return {id: doc.id, snapshot: doc, ...doc.data() } as Visit;
    }
    let queryRef: firebase.firestore.Query = this.collection.ref.orderBy('registered', 'desc').limit(pageSize);

    if (startAfterItem !== null) {
      queryRef = queryRef.startAfter(startAfterItem);
    } else if (endBeforeItem !== null) {
      queryRef = queryRef.startAt(startAtItem)
        .endBefore(endBeforeItem);

    } else if (startAtItem !== null) {
      queryRef = queryRef.startAt(startAfterItem);
    }

    queryRef = queryRef.where('location', '==', locationRef);

    return new Promise(async (success, error) => {

      try {
        const qSnapshot = await this.afs.collection<Visit>(CollectionName, ref => queryRef).get().toPromise();
        success(qSnapshot.docs.map(prepStructure));
      } catch (err) {
        error(err);
      }
    });

  }
}
