import { Component, OnInit } from '@angular/core';
import { DocumentSnapshot } from '@angular/fire/firestore';
import { UsersOnFireService, User } from 'src/app/services/users-on-fire/users-on-fire.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication-service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: User[];

  search: string;
  pageSize = 10;
  offset = 0;

  lastItem: DocumentSnapshot<User> = null;
  firstItem: DocumentSnapshot<User> = null;
  prevFistItem: DocumentSnapshot<User>[] = [];

  constructor(private auth: AuthenticationService, private afs: UsersOnFireService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this._getCollection();
  }

  private _getCollection(startAfterItem: DocumentSnapshot<User> = null,
                         startAtItem: DocumentSnapshot<User> = null,
                         endBeforeItem: DocumentSnapshot<User> = null) {

    this.afs.getList(this.pageSize, startAfterItem, startAtItem, endBeforeItem).then((list: User[]) => {

      this.users = [...list];

      if (list.length < 1) {
        this.lastItem = null;
      } else {
        this.firstItem = list.length > 0 ? (list.shift()).snapshot : null;
        this.lastItem = list.length > 0 ? (list.pop()).snapshot : null;
      }

    });
  }

  public pageChanged(direction: string): void {

    if (direction === 'forward') {
      this.offset += this.pageSize;

      this.prevFistItem.push(this.firstItem);
      this._getCollection(this.lastItem);
    } else if (direction === 'backward') {
      this.offset -= this.pageSize;
      this._getCollection(null, this.prevFistItem.pop(), this.lastItem);
    }
  }

  delete(id: string) {

    console.log(this.auth.getAuth().currentUser.uid, id);

    if (this.auth.getAuth().currentUser.uid === id) {
      return false;
    }

    console.log(this.auth.getAuth().currentUser.uid, id);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe(status => {

      if (status === 'confirmed') {
        this.afs.deleteItem(id);
        const startAfter = this.prevFistItem.length > 0 ? this.prevFistItem[this.prevFistItem.length - 1] : null;

        this._getCollection(startAfter);
      }
    });
  }

  get pageNumber() {
    return this.offset / this.pageSize + 1;
  }

  get displayColumns() {
    return ['email', 'role', 'actions'];
  }

}
