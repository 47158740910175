import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VisitsOnFireService, Visit } from 'src/app/services/visits-on-fire/visits-on-fire.service';
import { Observable } from 'rxjs';
import { LocationService } from 'src/app/services/location-on-fire/location.service';
import { DocumentReference, QueryDocumentSnapshot } from '@angular/fire/firestore';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})
export class VisitsComponent implements OnInit {

  visits: Visit[] = [];
  locationDoc: DocumentReference;

  search: string;
  pageSize = 10;
  offset = 0;

  lastItem: QueryDocumentSnapshot<Visit> | null;
  firstItem: QueryDocumentSnapshot<Visit> | null;
  prevFistItem: QueryDocumentSnapshot<Visit>[] = [];

  constructor(private visitsAfs: VisitsOnFireService, route: ActivatedRoute, locationsAfs: LocationService) {

    const locationId = route.snapshot.paramMap.get('id');

    locationsAfs.getItem(locationId).then((location) => {

      this.locationDoc = location.snapshot;
      this._getCollection(this.locationDoc);

    }).catch(err => {});
  }

  ngOnInit() {

  }

  private _getCollection(locationRef: DocumentReference,
                         startAfterItem: QueryDocumentSnapshot<Visit> = null,
                         startAtItem: QueryDocumentSnapshot<Visit> = null,
                         endBeforeItem: QueryDocumentSnapshot<Visit> = null) {

    this.visitsAfs.getList(locationRef, this.pageSize, startAfterItem, startAtItem, endBeforeItem)
    .then((list: Visit[]) => {

      this.visits = [...list];
      if (list.length < 1) {
        this.lastItem = null;
      } else {
        this.firstItem = list.length > 0 ? (list.shift()).snapshot : null;
        this.lastItem = list.length > 0 ? (list.pop()).snapshot : null;
      }
    })
    .catch((err) => console.error(err));
  }

  public pageChanged(direction: string): void {

    if (direction === 'forward') {
      this.offset += this.pageSize;

      this.prevFistItem.push(this.firstItem);
      this._getCollection(this.locationDoc, this.lastItem);

    } else if (direction === 'backward') {
      this.offset -= this.pageSize;

      console.log(this.prevFistItem.pop());
      this._getCollection(this.locationDoc, null, this.prevFistItem.pop(), this.lastItem);
    }
  }

  get pageNumber() {
    return this.offset / this.pageSize + 1;
  }

  get displayColumns() {
    return ['companyName', 'registered'];
  }

}
