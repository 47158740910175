import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyOnFireService, Company } from 'src/app/services/company-on-fire/company-on-fire.service';
import { ActivatedRoute } from '@angular/router';
import { OHour } from 'src/app/services/location-on-fire/location.service';
import { shared } from 'src/app/shared/shared';
import { FilesOnFireUploaderComponent } from 'src/app/components/files-on-fire-uploader/files-on-fire-uploader.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-company-item',
  templateUrl: './company-item.component.html',
  styleUrls: ['./company-item.component.scss']
})
export class CompanyItemComponent implements OnInit {

  @ViewChild('logoUploader', { static: false }) logoUploader: FilesOnFireUploaderComponent;
  @ViewChild('pathImgUploader', { static: false }) pathImgUploader: FilesOnFireUploaderComponent;
  @ViewChild('floorPlanImgUploader', { static: false }) floorPlanImgUploader: FilesOnFireUploaderComponent;

  company: Company;

  constructor(private readonly route: ActivatedRoute,
              private ngxLoader: NgxUiLoaderService,
              private fireStore: AngularFireStorage,
              private afs: CompanyOnFireService,
              private dialog: MatDialog) { }

  ngOnInit() {

    const id = this.route.snapshot.paramMap.get('id');

    this.afs.getItem(id).then((data) => {
        this.company = data;
      });
  }

  get isCompanyDefined() {
    return typeof this.company !== 'undefined';
  }

  get uploadeDirName() {
    return 'companies/' + this.company.id;
  }

  deleteFloorPlan() {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe(async status => {

      if (status === 'confirmed') {

        try {
          if (this.company.floor_plan) {
            this.floorPlanImgUploader.resetFilesList();
            this.fireStore.storage.refFromURL(this.company.floor_plan).delete();
            this.company.floor_plan_showtime = 0;
            this.company.floor_plan = null;
          }
        } catch (err) {
          console.error(err);
        }
      }
    });
  }

  deletePathImg() {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe(async status => {

      if (status === 'confirmed') {

        try {
          if (this.company.path) {
            this.pathImgUploader.resetFilesList();
            this.fireStore.storage.refFromURL(this.company.path).delete();
            this.company.path_showtime = 0;
            this.company.path = null;
          }
        } catch (err) {
          console.error(err);
        }
      }
    });
  }

  async updateCompanyDesc(payload: Company) {

    this.ngxLoader.start();

    const logoImgSnapshot = await this.logoUploader.startUpload(this.uploadeDirName);
    if (logoImgSnapshot != null) {

      if (this.company.logo) {
        this.fireStore.storage.refFromURL(this.company.logo).delete();
      }
      this.company.logo = payload.logo = await logoImgSnapshot.getDownloadURL().toPromise();
    }

    const pathImgSnapshot = await this.pathImgUploader.startUpload(this.uploadeDirName);
    if (pathImgSnapshot != null) {

      if (this.company.path) {
        this.fireStore.storage.refFromURL(this.company.path).delete();
      }
      this.company.path = await pathImgSnapshot.getDownloadURL().toPromise();
      this.company.path_showtime = this.company.path_showtime === 0 ? 30 : this.company.path_showtime;
    }

    if (!this.company.path) {
      this.company.path_showtime = 0;
    }

    const floorPlanImgSnapshot = await this.floorPlanImgUploader.startUpload(this.uploadeDirName);
    if (floorPlanImgSnapshot != null) {

      if (this.company.floor_plan) {
        this.fireStore.storage.refFromURL(this.company.floor_plan).delete();
      }
      this.company.floor_plan = await floorPlanImgSnapshot.getDownloadURL().toPromise();
      this.company.floor_plan_showtime = this.company.floor_plan_showtime === 0 ? 30 : this.company.floor_plan_showtime;
    }

    if (!this.company.floor_plan_showtime) {
      this.company.floor_plan_showtime = 0;
    }

    payload = {...this.company, ...payload};

    this.afs.updateItem(payload, this.company.id).then(() => {

      this.ngxLoader.stop();
    });
  }
}
