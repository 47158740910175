import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { tap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-files-on-fire-uploader',
  templateUrl: './files-on-fire-uploader.component.html',
  styleUrls: ['./files-on-fire-uploader.component.scss']
})
export class FilesOnFireUploaderComponent implements OnInit {

  @Output() fileUploaded: EventEmitter<AngularFireStorageReference>;
  @Output() fileAdded: EventEmitter<File>;

  files: FileList;

  preview: string | ArrayBuffer;

  // Main task 
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;

  // State for dropzone CSS toggling
  isHovering: boolean;

  constructor(private storage: AngularFireStorage, private afs: AngularFirestore) {
    this.fileUploaded = new EventEmitter<AngularFireStorageReference>();
    this.fileAdded = new EventEmitter<File>();
  }

  ngOnInit() {
  }


  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  addFiles(event: FileList) {
    this.files = event;

    const file = this.files.item(0);

    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent) => this.preview = (e.target as FileReader).result;
    reader.readAsDataURL(file);

    this.fileAdded.emit(file);
  }

  /*
  startUpload(event: FileList) {
    // The File object
    const file = event.item(0)

    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ')
      return;
    }

    // The storage path
    const path = `${this.folder}/${new Date().getTime()}_${file.name}`;

    // Totally optional metadata
    const customMetadata = { app: 'Company Locator PWA!' };

    // The main task
    this.task = this.storage.upload(path, file, { customMetadata })

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize(() => { console.log(this.storage.ref(path)); this.downloadURL = this.storage.ref(path).getDownloadURL(); }),
      tap(snap => {
        console.log(snap)
        if (snap.bytesTransferred === snap.totalBytes) {
          // Update firestore on completion
          this.afs.collection('photos').add({ path, size: snap.totalBytes })
        }
        this.onFileUploaded.emit(snap);
      })
    )
  }
  */

  startUpload(folder: string = '') {

    if (typeof this.files === 'undefined') {
      return new Promise<AngularFireStorageReference>((resolve) => resolve(null));
    }

    const file = this.files.item(0);

    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }

    // The storage path
    const path = `${folder}/${new Date().getTime()}_${file.name}`;

    // Totally optional metadata
    const customMetadata = { app: 'Company Locator PWA!' };

    // The main task
    this.task = this.storage.upload(path, file, { customMetadata });

    // Progress monitoring
    /*this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize(() => { console.log(this.storage.ref(path)); this.downloadURL = this.storage.ref(path).getDownloadURL(); }),
      tap(snap => {
        console.log(snap)
        if (snap.bytesTransferred === snap.totalBytes) {
          // Update firestore on completion
          this.afs.collection('photos').add({ path, size: snap.totalBytes })
        }
        this.onFileUploaded.emit(snap);
      })
    )*/

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    return new Promise<AngularFireStorageReference>((resolve, reject) => {

      this.snapshot = this.task.snapshotChanges().pipe(
        // The file's download URL
        finalize(() => {
          this.preview = '';
          resolve(this.storage.ref(path));
        }),
        tap(snap => {
          if (snap.bytesTransferred === snap.totalBytes) {
            // Update firestore on completion
          }
        })
      );
    });
  }

  resetFilesList() {
    delete this.files;
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
