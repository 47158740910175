import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication-service';
import { MustMatch } from 'src/app/_helpers';
import { MatDialog } from '@angular/material';
import { CheckPasswordDialogComponent } from 'src/app/components/check-password-dialog/check-password-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.email, Validators.required]),
    new_password: new FormControl('', [Validators.minLength(6)]),
    new_password_repeat: new FormControl(''),
  }, {
    validators: MustMatch('new_password', 'new_password_repeat')
  });

  user: any;

  constructor(private auth: AuthenticationService, private formBuilder: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {

    const user = this.auth.getUserData();
    this.profileForm.get('email').patchValue(user.email);
  }

  // convenience getter for easy access to form fields
  get f() { return this.profileForm.controls; }

  save() {

    if (this.profileForm.valid) {

      if (this.profileForm.get('email').touched ||
        (this.profileForm.get('new_password').touched) && this.profileForm.get('new_password').value !== '') {

        const dialogRef = this.dialog.open(CheckPasswordDialogComponent);

        dialogRef.afterClosed().subscribe(async status => {

          if (status === 'confirmed') {

            try {
              await this.auth.updateEmail(this.profileForm.get('email').value);
            } catch (err) {
              console.error(err);
              return;
            }

            try {
              await this.auth.updatePassword(this.profileForm.get('new_password').value);
            } catch (err) {
              console.error(err);
              return;
            }
          }
        });
      }
    }
  }
}
