import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication-service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DashboardTheme, ThemeSettingsService } from 'src/app/services/theme-settings/theme-settings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  dashboardTheme: BehaviorSubject<DashboardTheme>;

  constructor(public router: Router, public authService: AuthenticationService, private themeServise: ThemeSettingsService) {
    this.dashboardTheme = themeServise.theme$;
  }

  ngOnInit() {
  }

  signOut() {
    this.authService.SignOut();
    this.themeServise.reset();
    this.router.navigate(['login']);
  }
}
