import { Component, OnInit, Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Company, OHour } from 'src/app/services/company-on-fire/company-on-fire.service';
import { shared } from 'src/app/shared/shared';
import { Validators, FormControl, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {

  @Input() data: Company;
  @Output() submitEM = new EventEmitter();

  companyForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    room: new FormControl(''),
    oHours: new FormArray([])
  });

  oHoursArray: FormArray;

  constructor() {
  }

  ngOnInit() {

    this.companyForm.patchValue(this.data);
    this.oHoursArray = this.companyForm.get('oHours') as FormArray;

    this.data.oHours.forEach((hour) => {
      this.addOHour(hour);
    });

    if (this.data.oHours.length < 1) {
      // this.addOHour();
    }
  }

  addOHour(ohour: OHour | null = null) {

    if (this.oHoursArray.length > 6) {
      return;
    }

    const oHForm = new FormGroup(
      {
        day: new FormControl([], [Validators.required]),
        open: new FormControl('', [Validators.required]),
        close: new FormControl('', [Validators.required]),
      });

    if (ohour) {
      ohour.day = (Number.isInteger(ohour.day as number) ? [ohour.day] : ohour.day ) as number[];
      oHForm.patchValue(ohour);
    }

    this.oHoursArray.push(oHForm);
  }

  deleteOHour(idx) {
    this.oHoursArray.removeAt(idx);
  }

  get days() {

    /*const openingDays = this.data.oHours.map((oHour) => oHour.day).reduce((prev, current) => {

      if (prev instanceof Array) {
        current instanceof Array ? prev.concat(current) : prev.push(current);
        return prev;
      } else {
        return [prev as number, current as number];
      }
    }) as Array<number>;
    console.log(openingDays);
    return shared.days.filter((day) => openingDays.indexOf(day.id) < 0);
    */
   return shared.days;
  }

  submit() {

    if (this.companyForm.valid) {
      this.submitEM.emit(this.companyForm.value);
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.companyForm.controls; }
}
