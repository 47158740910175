import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/services/authentication/authentication-service';

@Component({
  selector: 'app-check-password-dialog',
  templateUrl: './check-password-dialog.component.html',
  styleUrls: ['./check-password-dialog.component.scss']
})
export class CheckPasswordDialogComponent implements OnInit {

  checkPasswordDialogForm: FormGroup = this.formBuilder.group({
    password: new FormControl('', [Validators.required])
  });

  constructor(private auth: AuthenticationService, private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<CheckPasswordDialogComponent>) { }

  ngOnInit() {
  }

  check() {

    if (this.checkPasswordDialogForm.valid) {

      const user = this.auth.getUserData();

      const password = this.checkPasswordDialogForm.get('password').value;

      this.auth.SignIn(user.email, password)
        .then((res) => this.dialogRef.close('confirmed')).catch((error) => {
        });
    }
  }

}
